$(function () {
    // Info Item
    $(".header-info-container .fixed-info-item .close").click(function () {
        $(".header-info-container").removeClass("active")
        $(".fixed-info-item, .fixed-info-bg").removeClass("active")
    })

    $(".header-info-container").mouseenter(function () {
        $(this).addClass("active")
        $(".fixed-info-item, .fixed-info-bg").addClass("active")
    })

    // Mobile Menu
    if ($(window).width() <= 1200) {
        $("header .header-container .header-mobile-menu").click(function () {
            $(this).toggleClass("active")
            $("body").toggleClass("overflow")

            $(".header-info-container").toggleClass("mobil-active")
            $(".header-menu").toggleClass("active")
        });

        // $(".header-menu > ul > li > a").click(function () {
        //     if ($(this).next("ul").length) {
        //         $(this).next("ul").slideToggle()
        //         $(this).toggleClass("active")

        //         $(this).parent().siblings().children("ul").slideUp()
        //         $(this).parent().siblings().children("a").removeClass("active")
        //     }
        // })

        $(".header-menu ul li a").click(function () {
            if ($(this).next("ul").length) {
                $(this).next("ul").slideToggle()
                $(this).toggleClass("active")

                $(this).parent().siblings().children("ul").slideUp()
                $(this).parent().siblings().children("a").removeClass("active")
            }
        })
    }
})