$(function () {
    // Home Slide
    new Swiper(".home-slide .swiper-container", {
        speed: 800,
        parallax: true,
        loop: true,
        autoplay: {
            delay: 5000,
        },
        allowTouchMove: false,
        simulateTouch: false,
        navigation: {
            nextEl: ".btn-next",
            prevEl: ".btn-prev",
        },
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
    });
})