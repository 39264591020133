// Layout
import './layout/header'

// Page
import './page/homepage'
import './page/faq'

Fancybox.bind('.fancybox-video', {
    openEffect: 'elastic',
    closeEffect: 'elastic',
    autoSize: true,
});

Fancybox.bind('.fancybox-pdf', {
    openEffect: 'elastic',
    closeEffect: 'elastic',
    autoSize: true,
    type: 'iframe',
    iframe: {
        preload: false // fixes issue with iframe and IE
    }
});

if ($(window).width() <= 1199) {
    $(".fancybox-pdf").each(function () {
        $(this).removeClass("fancybox-pdf")
        $(this).attr("target", "_blank")
    })
}

$(".tab-navbar a").click(function () {
    let itemIndex = $(this).index();

    $(this).addClass("active").siblings().removeClass("active")

    $(".tab-container .tab-item").each(function (index) {
        if (itemIndex == index) {
            $(this).addClass("active").siblings().removeClass("active")
        }
    })
})

// if (localStorage.getItem('lightcase') !== 'true') {
// if ($(".home-slide").length) {
//     var imgURLs = [
//         './assets/img/pop-up.jpg'
//     ];
//     var randomIndex = Math.floor(Math.random() * imgURLs.length);
//     var imgURL = imgURLs[randomIndex];

//     setTimeout(function () {
//         lightcase.start({
//             href: imgURL,
//         });

//         // setTimeout(() => {
//         //     localStorage.setItem('lightcase', 'true');
//         // }, 100);
//     }, 100);
// }
// }